// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBBV9M529sThoGSm_mkXiwJ8GYL-xdt9JM",
  authDomain: "yvomnewv2nd.firebaseapp.com",
  databaseURL: "https://yvomnewv2nd-default-rtdb.firebaseio.com",
  projectId: "yvomnewv2nd",
  storageBucket: "yvomnewv2nd.appspot.com",
  messagingSenderId: "27758475776",
  appId: "1:27758475776:web:73f34fc8ea7e1395e4f6e5",
  measurementId: "G-XZJQEW8XBE"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };